import { useEffect, useState } from 'react';
import {
    getWhiteLabelInfo,
    getInstallerCCTVCBranding,
} from '../../api/whiteLabel';

import { WEB_APP_BUILD_TIME, WEB_APP_VERSION } from '../../utils/version';

import routes from '../../pages/routes';

// Fetches white label styles
// Returns array [whiteLabelHostInfo, installerBranding]
const useWhiteLabel = (host, useInstallerBranding, installerId) => {
    const [whiteLabelHostInfo, setWhiteLabelHostInfo] = useState(null);
    const [installerBranding, setInstallerBranding] = useState(null); // Only used on CCTV Connect

    // Fetch white label info for host
    useEffect(() => {
        (async () => {
            const info = await getWhiteLabelInfo(host);

            // Set favicon
            document.getElementById('favicon').href = info.favicon;

            // Set page title
            let pageTitle = info.title || 'Cloud video surveillance';
            // Add version number to page title if this is a dev or staging site.
            // Could use `isDevelopment` constant here but thought the set of sites
            // with an exposed version number would be slightly different.
            if (window.location.hostname.match(/(alphaapp|staging|matthewdev|oanadev|simondev|testapp)/)) {
                pageTitle += ` (${WEB_APP_VERSION} built at ${WEB_APP_BUILD_TIME?.toUTCString()})`;

            }
            document.getElementById('page-title').innerHTML = pageTitle;

            removeRoutesFailingWhiteLabelCondition(info, routes);

            reformatColours(info);

            setWhiteLabelHostInfo(info);
        })();
    }, [host]);

    // Fetch branding for installer if this is CCTV Connect.
    // Can only be run once user data request has returned with installerid,
    // hence this request is made independently of the useEffect above.
    // Another option would be to wait until after account data has been received
    // before fetching any branding info. However fetching host white label info
    // does not need installerid (and this useEffect here is only required on
    // CCTV Connect), so did not want to increase loading time.
    useEffect(() => {
        if (useInstallerBranding && installerId) {
            (async () => {
                const installerCCTVCBranding = await getInstallerCCTVCBranding(
                    installerId
                );

                // On mobile app, secondary colour is used for nav.
                // On web we put logo on top of nav and colours may clash, so ignore secondary
                // colour and use grey instead.
                installerCCTVCBranding.secondaryColour = undefined;

                // If colour specified, reformat to new colours
                if (installerCCTVCBranding.colour) {
                    installerCCTVCBranding.accentColour =
                        installerCCTVCBranding.colour;
                    installerCCTVCBranding.buttonColour =
                        installerCCTVCBranding.colour;
                }

                installerCCTVCBranding.whiteOverAccent = true;

                setInstallerBranding(installerCCTVCBranding);
            })();
        } else {
            // If installer branding has previously been set, this clears it
            // This is required to clear installer branding on logout on CCTVC
            setInstallerBranding(null);
        }
    }, [useInstallerBranding, installerId]);

    return [whiteLabelHostInfo, installerBranding];
};

/*
    Up to seven colours can be specified:
        - gradients - used on buttons
        - buttonColour - used on buttons if no gradients specified
        - buttonTextColour - used as button text colour when normal white text does not work with buttonColour background
        - navColour - used as sidebar colour, fallback is buttonColour
        - navTextColour - used for selected text in sidebar
        - navFadedTextColour - used for non-selected text in sidebar
        - accentColour - used in many places around app (wherever VL pink is used), fallback is buttonColour

    This function manipulates legacy colour system (most styles only have a `colour` specified) into these seven properties.
*/
const reformatColours = (info) => {
    // Only need to refer to gradients from now on
    if (!info.gradients && info.gradient) {
        info.gradients = [info.gradient];
    }

    // Reformat colours
    if (!info.buttonColour && info.colour) {
        info.buttonColour = info.colour;
    }

    if (
        !info.navTextColour &&
        !info.navColour &&
        !info.portalSidebar &&
        info.colour &&
        info.buttonTextColour
    ) {
        // If we are using buttonColour as navColour (see code below), then navTextColour should match buttonTextColour
        info.navTextColour = info.buttonTextColour;

        // If we have set navTextColour then (if it has not already been chosen), set navFadedTextColour too
        // Do this by adding alpha value to hex code
        if (
            !info.navFadedTextColour &&
            /#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/.test(info.navTextColour)
        ) {
            if (/#[0-9a-fA-F]{6}/.test(info.navTextColour)) {
                // Six digit hex code
                info.navFadedTextColour = `${info.navTextColour}87`;
            } else if (/#([0-9a-fA-F]{3})/.test(info.navTextColour)) {
                // Three digit hex code
                info.navFadedTextColour = `${info.navTextColour}8`;
            }
        }
    }

    // Old portal uses portalSidebar
    if (!info.navColour && (info.portalSidebar || info.colour)) {
        info.navColour = info.portalSidebar || info.colour;
    }

    if (!info.accentColour && info.colour) {
        info.accentColour = info.colour;
    }
};

// Recursively work through routes object deleting any routes that fail white label condition
const removeRoutesFailingWhiteLabelCondition = (info, routes) => {
    for (const page in routes) {
        if (
            routes[page].whiteLabelCondition &&
            !routes[page].whiteLabelCondition(info)
        ) {
            delete routes[page];
        } else if (routes[page].routes) {
            removeRoutesFailingWhiteLabelCondition(info, routes[page].routes);
        }
    }
}

export default useWhiteLabel;
