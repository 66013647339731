import styled, { css } from 'styled-components';
import { colourByVariant, LIGHT_GREY, WHITE } from '../../utils/colours';

// Flex gap between toggle and labels in px
const gap = 10;

export const ToggleLabel = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    gap: ${gap}px;
    font-size: ${({ $large }) => ($large ? 16 : 14)}px;
    ${({ $marginTop }) =>
        $marginTop &&
        `margin-top: ${typeof $marginTop === 'number' ? $marginTop : 20}px;`}
    font-weight: 100;

    ${({ $disabled }) => $disabled ? 'opacity: .5;' : ''}
`;

ToggleLabel.displayName = 'ToggleLabel';

export const ToggleLabelText = styled.span`
    color: ${({ selected, selectedColour, variant, theme }) =>
        variant !== 'choice' ? colourByVariant(theme) : selected ? selectedColour : colourByVariant(theme)};
`;

ToggleLabelText.displayName = 'ToggleLabelText';

const SWITCH_WIDTH_LARGE = 40;
const SWITCH_WIDTH_SMALL = 30;
const SWITCH_HEIGHT_LARGE = 20;
const SWITCH_HEIGHT_SMALL = 15;

export const Checkbox = styled.input`
    /* Hide checkbox */
    opacity: 0 !important;

    ${({ $includesLabel, $large }) => $includesLabel ? `
        // When using a label tag, completely hide checkbox as it will get clicked whenever label is clicked
        width: 0;
        height: 0;

        /* Remove gap between Checkbox and Switch */
        margin-right: -${gap}px;
    ` : `
        // When no label, we must cover switch with checkbox so it gets clicked whenever user clicks switch
        min-width: ${$large ? SWITCH_WIDTH_LARGE : SWITCH_WIDTH_SMALL}px;
        height: ${$large ? SWITCH_HEIGHT_LARGE : SWITCH_HEIGHT_SMALL}px;
        position: absolute;
        z-index: 1;
        cursor: pointer;
        &[disabled] {
            cursor: not-allowed;
        }
    `}  
`;

Checkbox.displayName = 'Checkbox';

export const Switch = styled.span`
    cursor: ${({ $disabled }) => $disabled ? 'not-allowed' : 'pointer'};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: ${({ theme }) => colourByVariant(theme, 25)};
    ${({ $large }) => `
        border-radius: ${$large ? 26 : 22}px;
        min-width: ${$large ? SWITCH_WIDTH_LARGE : SWITCH_WIDTH_SMALL}px;
        height: ${$large ? SWITCH_HEIGHT_LARGE : SWITCH_HEIGHT_SMALL}px;
    `}
    position: relative;
    display: inline-block;
    transition: background-color 0.4s;

    :before {
        position: absolute;
        content: '';
        ${({ $large }) => `
            height: ${$large ? 20 : 15}px;
            width: ${$large ? 20 : 15}px;
            
        `}
        background-color: ${({ theme }) => theme === 'dark' ? WHITE() : LIGHT_GREY};
        transition: transform 0.4s;
        border-radius: 50%;
        left: 0;
    }

    input:checked + &:before {
        transform: translateX(${({ $large }) => ($large ? 19 : 16)}px);
    }

    ${({ variant, selectedColour }) =>
        variant === 'switch' &&
        css`
            &[data-selected="true"] {
                background-color: ${selectedColour};
            }
        `}

    /* Needs to show outline when checkbox in focus for accessibility */
    input:focus-visible + & {
        border: 2px solid #005fcc;
    }

    input:focus-visible + &:before {
        height: ${({ $large }) => `${$large ? 16 : 13}px`};
        width: ${({ $large }) => `${$large ? 16 : 13}px`};
    }
`;

Switch.displayName = 'Switch';
