import { forwardRef } from "react";
import PropTypes from 'prop-types';

import { Arrow, TooltipContainer } from './Tooltip.styles';


const Tooltip = forwardRef(({ children, arrowProps, placement, show = false, ...props }, ref) => {

    if (!show) {
        return null;
    }

    return (
        <TooltipContainer
            ref={ref}
            {...props}
        >
            {children}
            <Arrow {...arrowProps} $placement={placement} />
        </TooltipContainer>
    );
});

Tooltip.propTypes = {
    /** Whether tooltip is showing or not. */
    show: PropTypes.bool,
    /** Props to pass to arrow component. */
    arrowProps: PropTypes.shape({
        /** Ref for arrow element. */
        ref: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        /** Styles to apply. */
        style: PropTypes.object,
        /** Size of arrow. */
        size: PropTypes.number.isRequired
    }).isRequired,
    /** Position of tooltip as set by usePopper hook. Required if tooltip is open. */
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    /** Max width of tooltip. */
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;