import _ from 'lodash';
import { getAcronym } from './string';
import { logTzFormatError } from '../api/athena';
import translationKeys from '../translations/keys';

export const areDatesEqual = (date1, date2, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    const {year: date1Year, month: date1Month, day: date1Day} = getDateComponents(date1, timezone);
    const {year : date2Year, month : date2Month, day: date2Day} = getDateComponents(date2, timezone);
    return (
        date1Year === date2Year &&
        date1Month === date2Month &&
        date1Day === date2Day
    );
};

/**
 * 
 * @param {Date|number} originalDate 
 * @param {string} timezone 
 * @param {string|number} [overrideHour]
 * @param {string|number} [overrideMinute]
 * @returns {number} - timestamp in ms of original date in specified timezone at specified time/original time
 */
 export const getTimestamp = (originalDate, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone, overrideHour, overrideMinute) => {
    if (typeof originalDate === 'number') {
        return originalDate;
    }
    const year = originalDate.getFullYear();
    const month = originalDate.getMonth();
    const day = originalDate.getDate();
    const hour = overrideHour ?? originalDate.getHours();
    const minute = overrideMinute ?? originalDate.getMinutes();
    const second = overrideMinute ?? originalDate.getSeconds();
    const milliseconds = originalDate.getMilliseconds();

    const timezoneOffset = getUTCOffsetInMillis(timezone);

    const date = Date.UTC(year, month, day, hour, minute, second, milliseconds);

    return date - timezoneOffset;
};

// Returns difference in days between two dates (ignores time components)
export const getDateDifference = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const timeDifference = Math.abs(date1 - date2);
    return Math.ceil(msToDays(timeDifference));
};

/**
 * Converts time in ms to days.
 * @param {number} ms Time in ms.
 * @returns Time in days.
 */
export const msToDays = (ms) => {
    return ms / 86400000;
}

/**
 * Returns number of milliseconds in given number of days.
 * @param {number} days Number of days.
 * @returns {number} Milliseconds.
 */
export const daysToMs = days => {
    return days * 86400000;
}

/**
 * Returns number of milliseconds in given number of hours.
 * @param {number} hours Number of hours.
 * @returns {number} Milliseconds.
 */
export const hoursToMs = hours => {
    return hours * 3600000;
}

/**
 * Returns number of milliseconds in given number of minutes.
 * @param {number} minutes Number of minutes.
 * @returns {number} Milliseconds.
 */
export const minutesToMs = minutes => {
    return minutes * 60000;
}

/**
 * Converts a date to a Date object adjusted for a specified timezone.
 * Accepts date as a string or Date object. If no timezone is provided,
 * defaults to the user's local timezone. Useful for ensuring accurate time
 * before using functions like `getTimeFieldStringFromDate`.
 * @param {string|Date} date - The date to adjust.
 * @param {string} [timezone] - Optional. The IANA timezone string.  (e.g., "America/New_York"). Defaults to user local timezone
 * @returns {Date}
 */
export const getTimezonedDate = (date, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    if (typeof date === 'string' || typeof date === 'number') return new Date(new Date(date).toLocaleString('en-US', { timeZone: timezone }));
    else if (typeof date === 'object') return new Date(date.toLocaleString('en-US', { timeZone: timezone }));
};
export const getDateComponents = (date, timezone) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // 24hr format
    });
    const parts = formatter.formatToParts(date);
    const year = parseInt(parts.find(part => part.type === 'year').value, 10);
    const month = parseInt(parts.find(part => part.type === 'month').value, 10) - 1; // JavaScript months are 0-indexed
    const day = parseInt(parts.find(part => part.type === 'day').value, 10);
    const hour = parseInt(parts.find(part => part.type === 'hour').value, 10);
    const minute = parseInt(parts.find(part => part.type === 'minute').value, 10);
    const second = parseInt(parts.find(part => part.type === 'second').value, 10);

    // For day of the week, we need to create a new date object using the parsed year, month, and day
    const dateInTimezone = new Date(Date.UTC(year, month, day));
    const dayOfWeek = dateInTimezone.getUTCDay();

    return { year, month, day, dayOfWeek, hour: hour === 24 ? 0 : hour, minute, second };
};

export const createTimestampFromDateComponents = (year, month, day, hour, minute, timezone, isStart) => {

    const second = isStart ? 0 : 59;
    const millisecond = isStart ? 0 : 999;
    // Create a date string in the format "YYYY-MM-DDTHH:mm:ss.sss"
    const dateStr = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}.${String(millisecond).padStart(3, '0')}`;

    // Create a Date object from the date string in the specified timezone
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3,
        hourCycle: 'h23'
    });

    // Parse the formatted parts to get the local time in the specified timezone
    const formattedParts = dateFormatter.formatToParts(new Date(dateStr));
    const parts = {};
    formattedParts.forEach(({ type, value }) => {
        parts[type] = value;
    });

    // Create a new Date object with the extracted parts
    const tzDate = new Date(
        `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}.${parts.fractionalSecond}`
    );

    // Calculate the timezone offset in milliseconds
    const offset = tzDate.getTime() - Date.parse(dateStr);

    // Adjust the date to UTC
    const utcTimestamp = Date.parse(dateStr) - offset;

    return utcTimestamp;
};

export const createNewTimeInTimezone = (timezone, timestamp, newHour, newMinute, isStart) => {
    // Create a date object from the timestamp
    const originalDate = new Date(timestamp);

    // Format the date to the specified timezone
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3,
        hourCycle: 'h23'
    });

    // Parse the formatted parts to get the local time in the specified timezone
    const formattedParts = dateFormatter.formatToParts(originalDate);
    const parts = {};
    formattedParts.forEach(({ type, value }) => {
        parts[type] = value;
    });

    // Extract year, month, and day from the formatted date
    const year = parseInt(parts.year);
    const month = parseInt(parts.month) - 1; // JavaScript months are 0-indexed
    const day = parseInt(parts.day);
    const seconds = isStart ? 0 : 59;
    const milliseconds = isStart ? 0 : 999;

    // Create a new date in the specified timezone with the new hour and minute
    const newDateStr = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(newHour).padStart(2, '0')}:${String(newMinute).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${milliseconds}`;
    const newDate = new Date(newDateStr);

    // Adjust the new date to the specified timezone
    const newFormattedParts = dateFormatter.formatToParts(newDate);
    const newParts = {};
    newFormattedParts.forEach(({ type, value }) => {
        newParts[type] = value;
    });

    const adjustedNewDate = new Date(
        `${newParts.year}-${newParts.month}-${newParts.day}T${newParts.hour}:${newParts.minute}:${newParts.second}.${newParts.fractionalSecond}`
    );

    const offset = adjustedNewDate.getTime() - Date.parse(newDateStr);

    const newTimestamp = Date.parse(newDateStr) - offset;

    return newTimestamp;
};

// Helper function to create a Date object in the specified timezone
export const createDateAsTimezone = (inputDate, timezone) => {
    const date = new Date(inputDate);
    const dateString = date.toLocaleString("en-US", { timeZone: timezone });
    return new Date(dateString);
};

/**
 * Get time string in format HH:MM from Date.
 * @param {Date} date
 * @returns {string}
 */
export const getTimeFieldStringFromDate = date => `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

/**
 * Get time string in format HH:MM from Date.
 * @param {Date} date
 * @returns {string}
 */
export const getTimeFieldStringFromTimestamp = (timestamp, timezone) => {
    const { hour, minute } = getDateComponents(timestamp, timezone);
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
};

/**
 * Converts Date to 'yyyy-mm-dd' as accepted by input fields. Uses local time if timezone isn't specified
 * @param {Date} date
 * @returns {string}
 */
export const getInputFieldStringFromDate = (date) => {
    let actualDate;
    if (typeof date === 'object') {
        actualDate = date;
    } else if (typeof date === 'number') { //unix timestamp 
        actualDate = new Date(date);
    }
    return `${actualDate.getFullYear()}-${(actualDate.getMonth() + 1).toString().padStart(2, '0')}-${actualDate.getDate().toString().padStart(2, '0')}`;
};

/**
 * Creates Date object from 'yyyy-mm-dd' string in local time.
 * @param {string} string 
 * @returns {Date}
 */
 export const createDateInLocalTimeFromInputFieldString = string => {
    const date = new Date(string);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
};

/**
 * Creates string of date and time separated by a space according to passed in timezone and locale info
 * @param {Date} date
 * @param {string} locale
 * @param {string} timezoneName
 * @returns {string} 'date time'
 */
export const createDateTimeStringForTz = (date, locale, timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
    const dateString = date.toLocaleDateString(locale, { timeZone: timezoneName });
    const timeString = date.toLocaleTimeString(locale, { timeZone: timezoneName });

    return `${dateString} ${timeString}`;
};

/**
 * Generates a friendly name for a specified timezone using a given date.
 * This function is internal and used by `formatTimeZone` to append a readable timezone name.
 * If the timezone name cannot be extracted, the timezone identifier is returned.
 * @param {string} timezone - The IANA timezone string.
 * @param {Date} [date=new Date()] - Optional. The date to use for generating the name, defaults to the current date.
 * @returns {string} - The friendly name of the timezone (e.g. British Summer Time, Central Daylight Time) or the timezone identifier if the name cannot be extracted.
 */
export const getFriendlyTimezoneName = (timezone, date = new Date()) => {
    const options = {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'long'
    };
    const formatter = new Intl.DateTimeFormat(undefined, options);
    const parts = formatter.formatToParts(date);
    const timezonePart = parts.find(part => part.type === 'timeZoneName');
    return timezonePart ? timezonePart.value : timezone;
};


export const getUTCOffsetInMinutes = (timeZone) => {
    let timeZoneName="GMT";
    try {
        timeZoneName = Intl.DateTimeFormat("ia", {
            timeZoneName: "shortOffset",
            timeZone,
          })
            .formatToParts()
            .find((i) => i.type === "timeZoneName").value;
    } catch (e) {
        if (e instanceof RangeError) {
            timeZoneName = Intl.DateTimeFormat("ia", {
                timeZoneName: "short",
                timeZone,
              })
                .formatToParts()
                .find((i) => i.type === "timeZoneName").value;
        } else {
            throw(e);
        }
    }
    
    const offset = timeZoneName.slice(3);
    if (!offset) return 0;

    
    const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
    if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;
    
    const [, sign, hour, minute] = matchData;
    let result = parseInt(hour) * 60;
    if (minute) result += parseInt(minute);
    
    return result * (sign === '-' ? -1 : 1);
};

export const getUTCOffsetInMillis = (timeZone) => {
    return getUTCOffsetInMinutes(timeZone) * 60000;
};

/**
 * Calculates and formats the UTC offset for a specified timezone and date.
 * This function is internal and used by `formatTimeZone` to format the UTC offset.
 * Returns the formatted offset as a string in the format of "+HH:MM" or "-HH:MM".
 * @param {string} timeZone - The IANA timezone string to calculate the offset for.
 * @param {Date} [date=new Date()] - Optional. The date to calculate the offset, defaults to the current date.
 * @returns {string} - The formatted UTC offset.
 */
const getUTCOffsetFormatted = (timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone, date = new Date()) => {
    let timeZoneName = "GMT";
    if (timeZone) {
        try {
            timeZoneName = Intl.DateTimeFormat("ia", {
                timeZoneName: "shortOffset",
                timeZone,
            })
                .formatToParts(date)
                .find((i) => i.type === "timeZoneName").value;
        } catch (e) {
            if (e instanceof RangeError) {
                timeZoneName = Intl.DateTimeFormat("ia", {
                    timeZoneName: "short",
                    timeZone,
                })
                    .formatToParts(date)
                    .find((i) => i.type === "timeZoneName").value;
            } else {
                logTzFormatError(timeZone);
                timeZoneName="GMT";
            }
        }
        const offset = timeZoneName.slice(3);
        if (!offset) return "";
        const sign = offset.charAt(0);
        const result = offset.slice(1);
        if (result.includes(":")) {
            return `${sign}${result.split(":")[0].padStart(2, "0")}:${result.split(":")[1]}`;
        } else {
            return `${sign}${result.padStart(2, "0")}:00`;
        }
    } else return "";
};

/**
 * Formats a timezone into a user-friendly string with UTC offset and timezone name.
 * Example output: "BST (UTC +01:00)".
 * This function utilizes `getUTCOffsetFormatted` and `getFriendlyTimezoneName` to generate the formatted string.
 * @param {string} timezone - The IANA timezone string.
 * @param {Date} [date=new Date()] - Optional. The date to use for formatting, defaults to the current date.
 * @param {boolean} [showName=true] - Optional. Flag that decides if timezone name is shown or just UTC offset if flag is false
 * @returns {string} - The formatted timezone string.
 */
export const formatTimeZone = (timezone, date = new Date(), showName = true) => {
    let tz = timezone;
    if (timezone?.startsWith('-') || timezone?.startsWith('+')) {
        tz = `Etc/GMT${timezone}`;
    }
    const offset = getUTCOffsetFormatted(tz, date);
    const utcOffset = 'UTC' + (offset ? ` ${offset}` : '');
    const timezoneName = getFriendlyTimezoneName(tz, date);
    const timezoneNameAcronym = getAcronym(timezoneName);
    if (showName) {
        if (timezoneNameAcronym === 'CUT') {
            return 'UTC';
        } else if (timezoneName.includes('GMT')) {
            return timezoneName;
        }
        return `${timezoneNameAcronym} (${utcOffset})`;
    }
    else return utcOffset;
};

/**
 * Converts a duration in milliseconds into a human-readable string format representing days, hours, minutes, and seconds.
 * Example output for different durations: "20m34s", "1d3h25m54s".
 * This function breaks down the total milliseconds into days, hours, minutes, and seconds,
 * constructing a string based on these values. It omits any unit that does not exist (e.g., if there are 0 days, the days part is omitted).
 * @param {number} ms - The duration in milliseconds to be formatted.
 * @returns {string} - The formatted duration string, concatenating days ('d'), hours ('h'), minutes ('m'), and seconds ('s') as applicable.
 */
export const formatDuration = (ms) => {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let days = Math.floor(hours / 24);
    hours = hours % 24;

    return _.compact([
        days > 0 ? days + 'd' : '',
        (hours > 0 || days > 0) ? hours + 'h' : '',
        (minutes > 0 || hours > 0 || days > 0) ? minutes + 'm' : '',
        (seconds > 0 || minutes > 0 || hours > 0 || days > 0) ? seconds + 's' : ''
    ]).join('');
};

/**
 * Calculates and formats the time difference between a given date and the current time to the first two significant units (e.g., '1 year and 2 months' or '1 week and 2 days'). If the difference is less than an hour, it displays only minutes.
 * 
 * @param {string} dateString - The date string to compare against the current time, in a format recognized by the Date constructor.
 * @returns {string} A human-readable string representing the time difference, focusing on the first two significant units.
 */
export const getTimeDifferenceAsString = (dateString) => {
    const units = [
      { unit: 'year', ms: 31556952000 },
      { unit: 'month', ms: 2629746000 },
      { unit: 'week', ms: 604800000 },
      { unit: 'day', ms: 86400000 },
      { unit: 'hour', ms: 3600000 },
      { unit: 'minute', ms: 60000 }
    ];
    
    let difference = new Date() - new Date(dateString);
    let parts = [];
  
    for (let { unit, ms } of units) {
      let value = Math.floor(difference / ms);
      if (value) {
        parts.push(`${value} ${unit}${value > 1 ? 's' : ''}`);
        difference -= value * ms;
      }
      if (parts.length === 2) break; // Only collect the first two significant units
    }
  
    if (!parts.length) return 'Just now';
    return `${parts.join(' and ')} ago`;
};  


export const isTimestampOnGivenDate = (timestamp, date, timezone) => {
    const dateTimestampAtStartOfDate = createTimestampFromDateComponents(date.year, date.month, date.day, 0, 0, timezone, true);
    const dateTimestampAtEndOfDate = dateTimestampAtStartOfDate + 1000 * 60 * 60 * 24 - 1;
    return ( timestamp &&  dateTimestampAtStartOfDate <= timestamp && dateTimestampAtEndOfDate >= timestamp );
};
  
export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/**
 * Formats an array of day indices into a string of day names or abbreviations.
 * Consecutive days are grouped with a dash, non-consecutive days are separated by commas.
 * Uses full day names if the result contains 3 or fewer day groups, otherwise uses abbreviations.
 * @param {number[]} selectedDays - Array of day indices (0-6, representing Monday-Sunday)
 * @param {Function} t - Translation function
 * @returns {string} Formatted string of day names or abbreviations
 * @example
 * // Returns "Mon-Wed, Sat-Sun" if the result contains more than 1 day groups
 * // Returns "Mon-Tue, Sat" if the result contains 1 group
 * formatSelectedDays([0, 1, 2, 5, 6], t)
 */
 export const formatSelectedDays = (selectedDays, t) => {
    const sortedDays = [...selectedDays].sort((a, b) => a - b);
    const groups = sortedDays.reduce((acc, day, index, array) => {
      if (index === 0 || day !== array[index - 1] + 1) {
        acc.push([day]);
      } else {
        acc[acc.length - 1].push(day);
      }
      return acc;
    }, []);
  
    const useFullNames = groups.length <= 1;
  
    const formattedGroups = groups.map(group => {
      const getDayName = (day) => 
        useFullNames 
          ? t(translationKeys.time.days[weekDays[day]][weekDays[day].toUpperCase()])
          : t(translationKeys.time.days[weekDays[day]].ABBREVIATION);
  
      if (group.length === 1) {
        return getDayName(group[0]);
      } else {
        const start = getDayName(group[0]);
        const end = getDayName(group[group.length - 1]);
        return `${start}-${end}`;
      }
    });
  
    return formattedGroups.join(", ");
  };


export const sortSchedulesByDayAndTime = (a,b) => {
    const dayDiff = a.day_of_week - b.day_of_week;
    if (dayDiff !== 0) return dayDiff;
    const timeToSeconds = (timeStr) => {
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        return hours * 3600 + minutes * 60;
      };
    return timeToSeconds(a.local_time) - timeToSeconds(b.local_time);
};