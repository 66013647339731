import styled from 'styled-components';
import { VL_BLACK, WHITE } from '../../../utils/colours';

export const LabelAndInputContainer = styled.div`
    display: flex;
    flex-direction: ${({ $reverseLabel }) => $reverseLabel ? 'row-reverse' : 'row'};
    align-items: center;
    gap: 10px;
`;

LabelAndInputContainer.displayName = 'LabelAndInputContainer';

export const InputContainer = styled.input`
  width: ${({ large }) => (large ? 15 : 12)}px;
  height: ${({ large }) => (large ? 15 : 12)}px;
  min-width: ${({ large }) => (large ? 15 : 12)}px;
  min-height: ${({ large }) => (large ? 15 : 12)}px;
  border-radius: 50%;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  appearance: none;
  background-color: ${({ disabled, variant }) =>
    disabled ? variant === 'light' ? VL_BLACK(20) : WHITE(25) : '#b6b6b6'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:checked, &:indeterminate {
    background-color: ${({ accentColour }) => accentColour};
  }
  
  &:checked:not(:indeterminate)::after {
    content: '';
    position: absolute;
    width: 20%;
    height: 50%;
    border: solid ${({ whiteCheck }) => (whiteCheck ? WHITE() : VL_BLACK())};
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    transform: rotate(45deg);
    left: 33%;
    top: 16%;
  }

  &:indeterminate::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 16%;
    background: ${({ whiteCheck }) => (whiteCheck ? WHITE() : VL_BLACK())};
    left: 25%;
    top: 42%;
  }

  @media screen and (max-width: 600px) {
    width: 18px;
    height: 18px;
  }
`;

InputContainer.displayName = 'InputContainer';

export const Label = styled.label`
    font-weight: 100;
    font-size: ${({ $fontSize }) => typeof $fontSize === 'number' ? `${16}px` : $fontSize};

    ${({ isRequired }) =>
        isRequired &&
        `&:after { content: "*"; color: #ed2144; font-weight: 300; }`}
`;

Label.displayName = 'Label';
