import PropTypes from 'prop-types';

import { Paragraph } from './P.styles';

/** <p> tag with props to make common styling easier. */
const P = ({
    bold = false,
    marginTop = false,
    margin,
    centre = false,
    right = false,
    colour,
    fontSize,
    children,
    style: extraStyles = {},
    ...props
}) => {
    const style = {
        fontWeight: bold ? 300 : 100,
        margin,
        color: colour,
        fontSize,
        textAlign: centre ? 'center' : right ? 'right' : undefined,
        ...extraStyles
    };

    if (marginTop || marginTop === 0) {
        // Only include marginTop if it is given
        // Otherwise it would override `margin` for top margin
        style.marginTop = marginTop === true ? 30 : marginTop;
    }

    return (
        <Paragraph
            style={style}
            {...props}
        >
            {children}
        </Paragraph>
    );
};

P.propTypes = {
    /** Render text in bold. */
    bold: PropTypes.bool,
    /** Top margin. If value of 'true' given, standard 30px will be used. Otherwise can be string including unit or a number of px. This prop overrules any top margin set in 'margin' prop. */
    marginTop: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
    ]),
    /** CSS margin value. Numbers will be treated as px. */
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Centre align text. */
    centre: PropTypes.bool,
    /** Right align text. */
    right: PropTypes.bool,
    /** Text colour. */
    colour: PropTypes.string,
    /** Font size. Can be CSS string or number of px. */
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default P;
