import PropTypes from 'prop-types';
import { useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { getRegion } from '../../../api/managers/store';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';

import translationKeys from '../../../translations/keys';
import routes, { canUserAccessRoute } from '../../../pages/routes';

import useDemo from '../../../hooks/useDemo';

import ErrorBoundary from '../../error-boundary';
import ErrorScreen from '../../error-screen';
import WhiteLabel from '../../white-label';
import Tabs from '../../tabs';
import Icon from '../../icon';
import P from '../../p';
import Link from '../../link';
import { AppLayoutContainer, Banner, PageContent, SidebarContainer } from './AppLayout.styles';

// Icons
import { ReactComponent as LogOutIcon } from '../../../assets/images/icons/log-out.svg';
import { VL_BLACK, WHITE } from '../../../utils/colours';


// When showing a banner, set this to the timestamp of when banner was released
// When not showing a banner, this must be null
const BANNER_UPDATED = null;
// Restrict banner to only show for users in certain regions, e.g. ['euwest1']
// Set this to null if no restriction applies
const BANNER_REGIONS = null;
// Restrict banner to only show on certain sites
// Function receives object of white label info and must return a boolean
// Set this to null if no restriction applies
const BANNER_WHITE_LABEL_CONDITION = ({ isVideoloft, isTakeawayVendor, appName }) => isVideoloft && !isTakeawayVendor;

// Mobile nav menu shown for screen widths <= 800 px
const mobileBreakpoint = 800;

/** Manages layout for pages that should have the 'App layout' view. This includes a sidebar on the left of the screen. */
const AppLayout = ({
    routeConfig,
    logout,
    children,
    mobileApp,
    accountInfo,
    t,
    ...props
}) => {
    
    const { accentColour, accentColour2, whiteOverAccent, useAccentColour2, gradients, backgroundGradient, isVideoloft, ...whiteLabelInfo } = useWhiteLabelComponent();
    
    const { isDemo } = useDemo();

    const isVideoloftDemo = isDemo && isVideoloft;

    // Whether we are showing banner
    const [showBanner, setShowBanner] = useState(
        // We have a specific banner for VL demo
        isVideoloftDemo ||
        // Otherwise normal banner logic
        (
            // Do we want to show a banner?
            !!BANNER_UPDATED &&
            // Do not show on mobile app
            !mobileApp &&
            // Do not show on demo
            !isDemo &&
            // Check user region if required
            (!BANNER_REGIONS || BANNER_REGIONS.includes(getRegion())) &&
            // Check whether site is Videoloft if required
            (!BANNER_WHITE_LABEL_CONDITION || BANNER_WHITE_LABEL_CONDITION({...whiteLabelInfo, isVideoloft})) &&
            // We store when user last closed the banner in local storage so we can compare it to BANNER_UPDATED to see
            // whether they previously closed this version of the banner
            (!localStorage.bannerClosed || parseInt(localStorage.bannerClosed) < BANNER_UPDATED)
        )
    );    

    return (
        <AppLayoutContainer
            $mobileBreakpoint={mobileBreakpoint}
            $mobileApp={mobileApp}
            $banner={showBanner}
            {...props}
        >
            {/* Sidebar. Shown as header with popup menu on mobile. Hidden on mobile app. */}
            {!mobileApp && (
                <Sidebar
                    mobileBreakpoint={mobileBreakpoint}
                    logout={logout}
                    accountInfo={accountInfo}
                />
            )}

            {
                showBanner && (
                    <Banner
                        style={{
                            background: isVideoloft ? accentColour : accentColour2 ?? accentColour,
                            color: whiteOverAccent ? WHITE() : VL_BLACK()
                        }}
                    >
                        <P>
                            {
                                // There is a specific banner for VL demo
                                isVideoloftDemo ? (
                                    <Trans i18nKey={translationKeys.banner.VL_DEMO_BANNER_MESSAGE}>
                                        Arrange a free Videoloft Proof of Concept. <Link to="https://videoloft.com/request-proof-of-concept/" colour={whiteOverAccent ? WHITE() : VL_BLACK()}>Get Started</Link>
                                    </Trans>
                                ) : (
                                    // t(translationKeys.banner.BANNER_MESSAGE)
                                    <Trans i18nKey={translationKeys.banner.BANNER_MESSAGE} components={[<strong />]}>
                                        Scheduled maintenance: Wed, Mar 5, 5 AM EST – No downtime expected. Contact us if you experience any issues.
                                    </Trans>
                                )
                            }
                            
                        </P>
                        <Icon
                            variant="close"
                            accessibilityDescription="Close"
                            height={16}
                            width={16}
                            onClick={() => {
                                setShowBanner(false);
                                localStorage.bannerClosed = new Date().getTime();
                            }}
                        />
                    </Banner>
                )
            }

            <PageContent $mobileBreakpoint={mobileBreakpoint} $fullWidth={routeConfig?.layout?.fullWidth} $background={backgroundGradient}>
                {/* Error boundaries placed around whole page and then page content (excluding tabs). */}
                <ErrorBoundary
                    errorComponent={ErrorScreen}
                    message={t(translationKeys.errors.PAGE_ERROR)}
                >
                    {
                        // If this route does not have its own component (i.e. a renderComponent function)
                        // and has subroutes, then render tabs
                        !routeConfig?.renderComponent &&
                            routeConfig?.routes &&
                            !mobileApp && (
                                <Tabs
                                    large
                                    links={Object.values(routeConfig.routes)
                                        .map(({ translationKey, path }) => ({
                                            text: t(translationKey),
                                            path,
                                        }))}
                                />
                            )
                    }
                    <ErrorBoundary
                        errorComponent={ErrorScreen}
                        message={t(translationKeys.errors.PAGE_ERROR)}
                    >
                        {children}
                    </ErrorBoundary>
                </ErrorBoundary>
            </PageContent>
        </AppLayoutContainer>
    );
};

AppLayout.propTypes = {
    /** Config object from routes.js for the current route. Required unless on the 404 page. */
    routeConfig: PropTypes.object,
    /** Function called when user clicks 'Log out'. */
    logout: PropTypes.func.isRequired,
    /** Whether web app is being accessed through mobile app. Navigation is hidden if so. */
    mobileApp: PropTypes.bool,
    /** Account data of logged in user. */
    accountInfo: PropTypes.object.isRequired
};

const Sidebar = withTranslation()(
    ({
        logout,
        accountInfo,
        t,
    }) => {

        const { isDemo } = useDemo();

        // Get array of routes for sidebar
        // If arg `secondary` is false then it collects links for upper section
        // If arg `secondary` is true then it collects links for lower section
        const getLinksFromRoutes = (secondary = false) => {
            return Object.values(routes).reduce(
                (
                    acc,
                    routeConfig
                ) => {
                    if (
                        (secondary ? routeConfig.sidebarNavSecondary : routeConfig.sidebarNav) &&
                        canUserAccessRoute(routeConfig, accountInfo) &&

                        // If in demo mode, must ignore any pages that aren't included in demo
                        (!isDemo || routeConfig.demo)
                    ) {
                        return acc.concat({
                            text: t(routeConfig.translationKey),
                            path: routeConfig.path,
                            icon: routeConfig.icon,
                            beta: routeConfig.beta
                        });
                    }

                    return acc;
                },
                []
            );
        }

        // Links to go in main nav section
        let mainLinks = getLinksFromRoutes();

        // Links to go in lower nav section
        // Add 'Log out' at the end
        let secondaryLinks = getLinksFromRoutes(true).concat(
            {
                // In demo, it should say "Exit" rather than "Log out"
                text: t(isDemo ? translationKeys.navigation.EXIT : translationKeys.login.LOG_OUT),
                action: logout,
                icon: LogOutIcon,
            }
        );

        // Links to go in footer
        const footerLinks = [
            {
                text: t(translationKeys.footer.TERMS),
                path: routes.terms.path,
            },
            {
                text: t(translationKeys.footer.PRIVACY),
                path: routes.privacy.path,
            },
        ];

        return (
            <SidebarContainer>
                <WhiteLabel.Sidebar
                    mainLinks={mainLinks}
                    secondaryLinks={secondaryLinks}
                    footerLinks={footerLinks}
                />
            </SidebarContainer>
        );
    }
);

Sidebar.propTypes = {
    /** Function called when user clicks 'Log out'. */
    logout: PropTypes.func.isRequired,
    /** Account data of logged in user. */
    accountInfo: PropTypes.object.isRequired
};

export default withTranslation()(AppLayout);
