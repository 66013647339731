import { WHITE, VL_BLACK, MID_GREY, colourByVariant } from "../../utils/colours";

// In px
export const FIELD_WIDTH = 245;
export const NARROW_FIELD_WIDTH = 70;
export const DEFAULT_FIELD_HEIGHT = 34;
export const LARGE_FIELD_HEIGHT = 44;

export const inputStyling = ({ largeFontSize, large, fullWidth, narrowWidth, error, variant }) => `
    display: block;
    height: ${large ? LARGE_FIELD_HEIGHT : DEFAULT_FIELD_HEIGHT}px;
    width: ${
        fullWidth
            ? '100%'
            : narrowWidth
            ? `${NARROW_FIELD_WIDTH}px`
            : `${FIELD_WIDTH}px`
    };
    padding: 6px 12px;
    font-size: ${large || largeFontSize ? 16 : 14}px;
    border-radius: 4px;
    font-weight: 100;

    ${variant === 'light' ? `
        color: ${VL_BLACK()};
        background-color: ${WHITE()};
        border: 1px solid ${MID_GREY};
        
        ::placeholder {
            color: ${VL_BLACK(50)};
        }
    ` : `
        color: ${WHITE()};
        background-color: ${WHITE(15)};
        border: 1px solid ${WHITE()};

        ::placeholder {
            color: ${WHITE(50)};
        }
    `};
    
    ${error ? `&:not(:focus) { border-color: red; }` : ''}
`;

export const hideSpinButtons = `
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance: textfield;
    }
`;

export const hoverButtonStyling = ({ hoverBackgroundColour, hoverTextColour, disabled, loading, large, alternativeHoverCondition, $noArrow, variant }) => `
    background: linear-gradient(
        to bottom,
        ${hoverBackgroundColour},
        ${hoverBackgroundColour} 50%,
        ${colourByVariant(variant, 15)} 50%
    );
    border: none;
    align-items: center;
    font-weight: 100;
    padding: ${large ? `0 20px` : `0 10px`};
    height: ${large ? 40 : 30 }px;
    min-height: ${large ? 40 : 30 }px;
    border-radius: 50px;
    cursor: pointer;
    color: ${alternativeHoverCondition ? `${hoverTextColour} !important` : colourByVariant(variant)};
    background-size: 100% 200%;
    background-position-y: ${alternativeHoverCondition ? 0 : 100}%;
    background-repeat: no-repeat;
    transition: background 0.3s;

    ${!large ? 'font-size: 90%;' : ''}

    /* Arrow */
    ${loading || disabled || $noArrow ? '' : `
        ::after {
            content: "";
            margin-left: ${large ? 7 : 5}px;
            display: block;
            width: 0px;
            height: 0px;
            border-left: ${large ? 4 : 3}px solid transparent;
            border-right: ${large ? 4 : 3}px solid transparent;
            border-${alternativeHoverCondition ? 'bottom' : 'top'}: ${large ? 5 : 4}px solid ${
                alternativeHoverCondition ? hoverTextColour : colourByVariant(variant)
            };
            transition: border-bottom-color 0.3s, border-top-color 0.3s; 
        }
    `}

    ${disabled ? `
        opacity: 0.4;
        cursor: not-allowed;
    ` : `
        @media (hover: hover) {
            :hover {
                color: ${hoverTextColour};
                background-position-y: 0%;

                :hover::after {
                    border-top-color: ${hoverTextColour};
                    border-bottom-color: ${hoverTextColour};
                }
            }
        }
    `}
`;
