// TODO: When "switch" variant starts getting used, need to write tests for it. Same for 'marginTop' prop.

import PropTypes from 'prop-types';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { Flex } from '../layout';

import {
    ToggleLabel,
    ToggleLabelText,
    Switch,
    Checkbox,
} from './Toggle.styles';

/** Toggle with two variants: choosing between two options or turning something on/off. */
export const Toggle = ({
    variant = 'choice',
    leftLabel,
    rightLabel,
    value,
    onChange,
    name,
    marginTop,
    id,
    large = false,
    selectedLabelColour,
    disabled = false,
    theme,
    checkboxProps,

    // React handlers to pass through to checkbox
    onFocus,
    onBlur,

    ...props
}) => {

    const { accentColour, accentColour2 } = useWhiteLabelComponent();
    const selectedColour = selectedLabelColour ?? accentColour2 ?? accentColour;

    const includesLabel = !!(leftLabel || rightLabel);

    return (
        <ToggleLabel
            $marginTop={marginTop}
            $large={large}
            $disabled={disabled}
            as={!includesLabel ? 'div' : undefined}
            {...props}
        >
            {
                leftLabel && (
                    <Flex column gap={5}>
                        <ToggleLabelText
                            selected={!value}
                            selectedColour={selectedColour}
                            variant={variant}
                            theme={theme}
                        >
                            {leftLabel}
                        </ToggleLabelText>
                    </Flex>
                )
            }
            <Checkbox
                type="checkbox"
                checked={value}
                onChange={onChange}
                name={name}
                disabled={disabled}
                // Put any given HTML id on checkbox
                id={id}
                {...checkboxProps}

                onFocus={onFocus}
                onBlur={onBlur}
                
                $includesLabel={includesLabel}
                $large={large}
            />
            <Switch
                variant={variant}
                selectedColour={selectedColour}
                $large={large}
                $disabled={disabled}
                theme={theme}
                data-selected={value}
            />
            {
                rightLabel && (
                    <ToggleLabelText
                        selected={value}
                        selectedColour={selectedColour}
                        variant={variant}
                        theme={theme}
                    >
                        {rightLabel}
                    </ToggleLabelText>
                )
            }
        </ToggleLabel>
    );
};

Toggle.propTypes = {
    /** Type of toggle. 'choice' is used when selecting between two options. 'switch' is for on/off use cases. */
    variant: PropTypes.oneOf(['choice', 'switch']),
    /** Label for left side of toggle when `value` is false. */
    leftLabel: PropTypes.string,
    /** Label for right side of toggle when `value` is true. */
    rightLabel: PropTypes.string,
    /** Whether toggle is true or false. */
    value: PropTypes.bool,
    /** Function to call whenever user changes the toggle's value. */
    onChange: PropTypes.func,
    /** Name of field. Used as HTML `name` attribute. */
    name: PropTypes.string,
    /** Add top margin. Set to true for default value or provide a value for margin in px. */
    marginTop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /** Two sizes: normal and large. */
    large: PropTypes.bool,
    /** Disable toggle. */
    disabled: PropTypes.bool,
    /** Additional props to pass directly to underlying checkbox. */
    checkboxProps: PropTypes.object
};

export default Toggle;
