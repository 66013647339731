
/**
 * Get snapshot of current video frame. If applicable, this function can also allow for zooming and panning.
 * @param {HTMLVideoElement} video Video element.
 * @param {HTMLElement} [container] Parent element that video is rendered within. Only required if allowing for zooming/panning.
 * @param {object} [zoomTransformState] Transform state provided by react-zoom-pan-pinch. Only required if allowing for zooming/panning.
 * @returns {HTMLCanvasElement} Snapshot drawn on canvas element.
 */
export const getVideoSnapshot = (video, container, zoomTransformState = { positionX: 0, positionY: 0, scale: 1 }) => {
    // Get intrinsic dimensions of video
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;
    const videoAspectRatio = videoWidth / videoHeight;

    /*
        We effectively have three different boxes to consider when calculating what part of video
        frame to download. From outermost to innermost:
            - Window: This is the static 16:9 rectangle we render the video in
            - Transform: This is the ReactZoomPanPinch 16:9 rectangle to which transformations are applied.
                         It is at least as large as the "Window" and entirely overlaps it.
            - Transformed video: This is the video frame inside the "Transform". If the video is 16:9 it will
                                 be the same size as the "Transform". For other aspect ratios, it will be
                                 smaller and either match the width or the height.
    */

    // Get dimensions of the "Window"
    const windowWidth = container?.offsetWidth ?? videoWidth;
    const windowHeight = container?.offsetHeight ?? videoHeight;
    const windowAspectRatio = windowWidth / windowHeight;

    // Get px coordinates of the "Transform"
    // (0,0) is the top left of the "Window"
    const { positionX, positionY, scale } = zoomTransformState;
    const transformTopLeftX = positionX;
    const transformTopLeftY = positionY;
    const transformWidth = windowWidth * scale;
    const transformHeight = windowHeight * scale;

    // Get the coordinates of the "Transformed Video"
    let transformedVideoWidth, transformedVideoHeight, transformedVideoTopLeftX, transformedVideoTopLeftY;
    if (videoAspectRatio >= windowAspectRatio) {
        // If video's aspect ratio is wider than 16:9, then "Transformed video" will be full width of "Transform"
        transformedVideoWidth = transformWidth;
        transformedVideoHeight = transformedVideoWidth / videoAspectRatio;
        transformedVideoTopLeftX = transformTopLeftX;
        // Video will be vertically centred
        transformedVideoTopLeftY = transformTopLeftY + (transformHeight - transformedVideoHeight) / 2;
    } else {
        // If video's aspect ratio is taller than 16:9, then "Transformed video" will be full height of "Transform"
        transformedVideoHeight = transformHeight;
        transformedVideoWidth = transformedVideoHeight * videoAspectRatio;
        transformedVideoTopLeftY = transformTopLeftY;
        // Video will be horizontally centred
        transformedVideoTopLeftX = transformTopLeftX + (transformWidth - transformedVideoWidth) / 2;
    }

    // Get the bounding edges of the overlap between the "Window" and the "Transformed video"
    // This overlap is the cropped area we want to download
    // These are still in px coordinates with the origin being top left of the "Window"
    const boundingLeftX = Math.max(0, transformedVideoTopLeftX);
    const boundingRightX = Math.min(windowWidth, transformedVideoTopLeftX + transformedVideoWidth);
    const boundingTopY = Math.max(0, transformedVideoTopLeftY);
    const boundingBottomY = Math.min(windowHeight, transformedVideoTopLeftY + transformedVideoHeight);

    // Convert those bounding edges into px coordinates relative to the intrinsic size of the video
    // I.e. Each will be between (0,0) in top left and (videoWidth, videoHeight) in bottom right
    // We do this by calculating how far across/down the video the bounding edge lies as a fraction, and then multiplying by video width/height
    const boundingLeftXInVideoCoordinates = ((boundingLeftX - transformedVideoTopLeftX) / transformedVideoWidth) * videoWidth;
    const boundingRightXInVideoCoordinates = ((boundingRightX - transformedVideoTopLeftX) / transformedVideoWidth) * videoWidth;
    const boundingTopYInVideoCoordinates = ((boundingTopY - transformedVideoTopLeftY) / transformedVideoHeight) * videoHeight;
    const boundingBottomYInVideoCoordinates = ((boundingBottomY - transformedVideoTopLeftY) / transformedVideoHeight) * videoHeight;

    // Width and height of image file
    // Image will be cropped video frame
    const imageWidth = boundingRightXInVideoCoordinates - boundingLeftXInVideoCoordinates;
    const imageHeight = boundingBottomYInVideoCoordinates - boundingTopYInVideoCoordinates;

    // Create canvas element
    const canvas = document.createElement('canvas');
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    const context = canvas.getContext('2d');

    // Draw cropped video frame on canvas
    context.drawImage(video, boundingLeftXInVideoCoordinates, boundingTopYInVideoCoordinates, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight);

    return canvas;
}

/**
 * Video server path or URL for a period of streamable recorded video.
 * @param {string} uidd Camera uidd.
 * @param {number} startTime Timestamp of start of footage in ms.
 * @param {number} endTime Timestamp of end of footage in ms.
 * @param {string} cameraRecordedStreamName Stream name to use for recorded camera footage.
 * @param {string} token Auth token.
 * @param {object} [config] Additional config.
 * @param {string} [config.videoServer] URL of video server in appropriate region.
 * @param {('mp4'|'mpegts')} [config.format] Video type.
 * @param {boolean} [config.padding] Pad sections of time frame with no video.
 * @returns {string} URL.
 */
export const getRecordedVideoUrl = (uidd, startTime, endTime, cameraRecordedStreamName, token, { videoServer, format = 'mp4', padding = false } = {}) => {
    const [uid, deviceId] = uidd.split('.');

    const queryString = [];
    if (padding) {
        queryString.push('padding=true');
    }
    queryString.push(`token=${token}`);

    return `${videoServer || ''}/stream/${format}/${uid}/${deviceId}/${startTime / 1000}/${endTime / 1000}/${cameraRecordedStreamName}${queryString.length > 0 ? `?${queryString.join('&')}` : ''}`;
}