import styled from 'styled-components';

import { VL_BLACK, WHITE, DARK_GREY, TURQUOISE, SUBTLE_DROP_SHADOW, VL_RED, MID_GREY } from '../../utils/colours';
import { hoverButtonStyling } from '../form/helpers';

// In px
const normalMarginTop = 20;
const largeMarginTop = 30;

// StyledButton has background (amongst other styling)
export const StyledButton = styled.button`
    min-height: ${({ $minHeight }) => $minHeight ?? 40}px;
    border: none;
    border-radius: ${({ $square }) => $square ? 5 : 35}px;
    font-weight: 100;
    padding: ${({ $large }) => ($large ? '18px 45px' : '8px 24px')};
    font-size: ${({ $large }) => ($large ? 18 : 16)}px;
    box-shadow: none;
    cursor: pointer;
    min-width: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    ${({ $marginTop, $large }) =>
        $marginTop &&
        `margin-top: ${$large ? largeMarginTop : normalMarginTop}px;`}

    color: ${({ $variant, $textColour }) => {
        switch ($variant) {
            case 'primary':
            case 'primary-1':
            case 'primary-2':
            default:
                return $textColour ?? '#fff';
            case 'secondary':
                return VL_BLACK();
            case 'secondary-grey':
            case 'secondary-transparent':
                return WHITE();
            case 'secondary-white':
                return VL_BLACK();
            case 'secondary-whitewash':
                return WHITE();
            case 'danger':
                return VL_RED;
            case 'select':
            case 'input':
                return VL_BLACK();
        }
    }};
    
    ${({ $variant, $backgroundColour, $alternativeTextColour, $accentColour, disabled, loading, $large, $isActive, $noArrow }) => {
        switch ($variant) {
            case 'primary':
            case 'primary-1':
            case 'primary-2':
            default:
                return $backgroundColour ? 
                    `background-color: ${$backgroundColour};` : 
                    $accentColour ? 
                    `background-color: ${$accentColour};` : 
                    `background-color: ${TURQUOISE}`;
            case 'secondary':
                return `
                    background-color: ${WHITE(50)};
                `;
            case 'secondary-grey':
                return `
                    background-color: ${DARK_GREY};
                    border: none;
                `;
            case 'secondary-white':
                return `background-color: ${WHITE()};`;
            case 'secondary-transparent':
                return `
                    background-color: transparent;
                    border: 1px solid ${WHITE()};
                `;
            case 'secondary-whitewash':
                return `
                    background-color: ${WHITE(15)};
                    border: none;
                `;
            case 'danger':
                return `
                    background: none;
                    border: 1px solid ${VL_RED};
                `;
            case 'hover':
                return hoverButtonStyling({
                    hoverBackgroundColour: $backgroundColour,
                    hoverTextColour: $alternativeTextColour,
                    disabled: disabled,
                    loading: loading,
                    large: $large,
                    alternativeHoverCondition: $isActive,
                    $noArrow: $noArrow
                });
            case 'select':
                return `
                    background-color: ${WHITE()};
                    border: 1px solid ${VL_BLACK()};
                    border-radius: 2px;
                    box-shadow: ${SUBTLE_DROP_SHADOW};
                    padding: 2px 10px;
                    max-height: 27px;
                    min-height: 27px;
                    width: 100%;
                `;
            case 'input':
                return `
                    background-color: ${WHITE()};
                    border: 1px solid ${MID_GREY};
                    border-radius: 4px;
                    padding: 5px;
                    height: 34px;
                    width: 100%;
                    justify-content: flex-start;
                    position: relative;
                    ::after {
                        content: "v";
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%) scaleY(0.6);
                        color: #666; /* Adjust color as needed */
                        font-size: 14px;
                        pointer-events: none;
                    }
                `;
        }
    }}

    ${'' /* ${({ $variant, $gradients }) => {

        if (!$gradients) {
            return '';
        }

        switch ($variant) {
            case 'primary':
            case 'primary-1':
                return `background-image: ${$gradients[0]};`;
            case 'primary-2':
                return `background-image: ${$gradients[1] ?? $gradients[0]};`;
            default:
                return '';
        }
    }}; */}

    [disabled],
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    ${({ $showingSpinner }) =>
        $showingSpinner && `color: transparent !important; cursor: default;`}
`;

StyledButton.displayName = 'StyledButton';

// TextButton is only text (no background, etc.)
// Resembles an <a> tag
// Use TextButton when <button> is more semantically appropriate than <a>
export const TextButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 100;
    text-decoration: ${({ $variant }) => $variant === 'text-hover' ? 'none': 'underline'};
    font-size: ${({ $large, $variant }) =>
        $variant === 'inline' ? 'inherit' : ($large ? 18 : 16) + 'px'};
    color: ${({ $variant }) => ($variant === 'inline' ? 'inherit' : $variant === 'text-dark' ? VL_BLACK() : WHITE())};
    padding: 0;
    text-align: left;
    ${({ $marginTop, $large, href, to }) =>
        $marginTop &&
        // <a> will need `display: inline-block;`
        `margin-top: ${$large ? largeMarginTop : normalMarginTop}px;
        ${href || to ? 'display: inline-block;' : ''}`}


    &:hover {
        text-decoration: none;
        ${({ $variant, $accentColour }) => $variant === 'text-hover' ? `color: ${$accentColour}; ` : ''}
    }

    [disabled],
    &:disabled {
        text-decoration: none;
        cursor: not-allowed;
    }
`;

TextButton.displayName = 'TextButton';

// StyledButton is default
export default StyledButton;
