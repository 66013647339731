/**
 * @constant {string} WEB_APP_VERSION
 * @description The current version of the web app.
 * This constant is set at build time to match the version in package.json.
 * If the build process fails to set the version, it will default to 'unknown'.
 */
export const WEB_APP_VERSION = process.env.REACT_APP_VERSION || 'unknown';

/**
 * @constant {string} WEB_APP_BUILD_TIME
 * @description The timestamp when this web app build was generated.
 */
export const WEB_APP_BUILD_TIME = process.env.REACT_APP_BUILD_TIME ? new Date(process.env.REACT_APP_BUILD_TIME) : null;

window.WEB_APP_VERSION = WEB_APP_VERSION;
window.WEB_APP_BUILD_TIME = WEB_APP_BUILD_TIME;