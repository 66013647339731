const MAX_QUERY_STRING_LENGTH = 1200;

/**
 * Splits an array of items into chunks based on their combined string length
 * @param {string[]} items - Array of strings to split
 * @returns {Array<string[]>} Array of chunks
 */
export const splitIntoChunks = (items) => {
    return items.reduce((acc, item) => {
        if (!acc.length || (acc[acc.length - 1].join('').length + item.length > MAX_QUERY_STRING_LENGTH)) {
            acc.push([]);
        }
        acc[acc.length - 1].push(item);
        return acc;
    }, []);
};
