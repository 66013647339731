import { makePrivateRequestAsUser } from "./managers/requestBuilder";

/**
 * Get domain of an alive and running logger.
 * 
 * TODO: Consider caching the response to save requesting every time.
 * 
 * @param {string} [region] Find a logger in this region.
 * @returns {Promise<string>} Logger domain.
 */
export const getValidLogger = makePrivateRequestAsUser(async ({ makePrivateRequest, region }) => {
    const loggerResponse = await makePrivateRequest(
        'auth',
        'GET',
        '/logger'
    );

    return loggerResponse.data;
});