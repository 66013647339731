import PropTypes from 'prop-types';

import { Select, Option } from './Dropdown.styles';

/** Dropdown field. Remember to include aria-label if not using a <label>. */
const Dropdown = ({
    onChange,
    name,
    error = false,
    value,
    descriptionId,
    required = false,
    large = false,
    fullWidth = false,
    narrowWidth = false,
    options,
    ...props
}) => {
    return (
        <>
            <Select
                value={value}
                onChange={onChange}
                name={name}
                id={name}
                required={required}
                aria-invalid={error}
                aria-describedby={descriptionId}
                large={large}
                fullWidth={fullWidth}
                narrowWidth={narrowWidth}
                error={error}
                {...props}
            >
                {options.map((option) => {
                    // option is either string or object
                    let value, text;
                    if (typeof option === 'object') {
                        ({ value, text } = option);
                    } else {
                        value = option;
                        text = option;
                    }

                    return (
                        <Option value={value} key={value}>
                            {text}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

Dropdown.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. */
    name: PropTypes.string,
    /** Chosen value is invalid. Sets `aria-invalid` to true and renders red border around element. */
    error: PropTypes.bool,
    /** Value of select field. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    /** Id of element describing this field. Will be included in `aria-describedby` attribute. */
    descriptionId: PropTypes.string,
    /** Whether this is a required field. */
    required: PropTypes.bool,
    /** Whether the select field should be a larger size. */
    large: PropTypes.bool,
    /** Whether select field should fill width of container. */
    fullWidth: PropTypes.bool,
    /** Set field to narrow width. */
    narrowWidth: PropTypes.bool,
    /** List of possible options. Each array element can be object with properties `value` and `text` or just a string which is used as both option's value and text. */
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                /** Text to show to user in dropdown representing this option. */
                text: PropTypes.string.isRequired,
                /** Value of this option. */
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                    .isRequired,
            }),
        ])
    ).isRequired,
};

export default Dropdown;
