import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import { Flex } from '../layout';
import Icon from '../icon';
import Span from '../span';
import { Tag } from './Tags.styles';
import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

const Tags = ({ tags, large, variant = 'dark', onRemove, t, tReady, style, ...props }) => {

    const { accentColour } = useWhiteLabelComponent();

    if (tags.length === 0) {
        return null;
    }

    return (
        <Flex
            wrap
            gap={10}
            style={{
                overflow: 'hidden',
                ...(style ?? {})
            }}
            {...props}
        >
            {
                tags.map(tag => (
                    <Tag
                        key={tag}
                        large={large}
                        variant={variant}
                        common={props.commonTags?.includes(tag) ? accentColour : undefined}
                    >
                        <Span>
                            {tag}
                        </Span>
                        {
                            onRemove && (
                                <Icon
                                    variant="close"
                                    width={9}
                                    height={9}
                                    onClick={() => onRemove(tag)}
                                    type="button"
                                    accessibilityDescription={tReady ? t(translationKeys.cameras.tags.REMOVE_TAG) : 'Remove tag'}
                                />
                            )
                        }
                    </Tag>
                ))
            }
        </Flex>
    );
}

Tags.propTypes = {
    /** Tags to display. */
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Use larger size. */
    large: PropTypes.bool,
    /** Function called when user clicks close icon on tag. Gets passed the selected tag. Close icon will not appear if this prop is not provided. */
    onRemove: PropTypes.func,
    dark: PropTypes.oneOf(['dark', 'light'])
}

export default withTranslation()(Tags);