import _ from "lodash";

export const roundTo2dp = (number) => roundToDp(2, number);

export const showTo2dp = (number) => roundTo2dp(number).toFixed(2);

/**
 * Rounds a value to a given number of decimal places.
 * @param {number} dp number of decimal places (must be >= 1)
 * @param {number} number number to round
 * @returns {number} rounded number
 */
export const roundToDp = (dp, number) => Math.round((number + Number.EPSILON) * Math.pow(10, dp)) / (Math.pow(10, dp));

export const getFieldValue = (field) => {
    if (
        field &&
        typeof field !== 'object'
    ) {
        return field;
    } else if (
        field &&
        typeof field === 'object' &&
        Object.keys(field).includes('value')
    ) {
        return field.value;
    }
    if (field === 0 || field === '') {
        return field;
    }
    return null;
};

export const getUniqueNonUniqueValues = (array) => _.keys(_.pickBy(_.groupBy(array), group => group.length > 1));