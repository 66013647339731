import _ from 'lodash';
import EventSource from 'eventsource'; // Need this so that we can use /cameras/realtime/live API with Authorization in the header

import {
    getUiddsPerLogger,
} from './helpers';
import { getEventObject } from './events';
import { logCameraTaskSent, logUninstallTask } from './athena';
import { getValidLogger } from './logger';
import { getAuthToken } from './managers/store';
import { makePrivateRequestAsRole, makePrivateRequestAsUser, makePrivateRequestWithData, privateAuthenticatorRequest, privateLoggerRequest } from './managers/requestBuilder';

import { logError } from '../utils/errors';
import { splitIntoChunks } from '../utils/requestHelpers';

// Helper functions

// Takes object returned from /cameras/status or realtime API as only argument
// Returns object containing only the properties the app needs
// May rename or reformat some properties too
const getStatusObject = ({ lastthumb, status, live, locallive, uidd, lastactivity, laststill, liveStreamName, wowza }) => ({
    // Time of last thumbnail image
    lastThumbTime: lastthumb,

    // Time of last camera activity
    lastActivity: lastactivity,

    // Time of last still image,
    lastStill: laststill * 1000,

    // Status 'live' means the camera is online but not necessarily streaming live
    // Renaming this status to 'online' so there is a clear distinction between camera status and whether an online camera is live streaming
    status: status === 'live' ? 'online' : status,

    // Whether camera is sending a live stream (to Wowza)
    liveStreaming: !!live,

    // Whether camera is sending a live stream to local live server
    localLiveStreaming: !!locallive,

    // Stream name to use for live video
    liveStreamName,
    wowza,

    uidd,
    deviceId: parseInt(uidd.split('.')[1]),
});

// Takes object returned by /viewerInfo for one camera
// Returns object containing only the properties the app needs
const getCameraObject = ({
    logger,
    uid,
    id: deviceId,
    wowza,
    name,
    cloudRecordingEnabled,
    analyticsEnabled,
    analyticsScheme,
    audioEnabled,
    permission,
    romEnabled,
    ptzEnabled,
    mainstreamLive,
    tags,
    cloudAdapterId,
    cloudAdapterVersion,
    localLiveHosts,
    localLiveId,
    macAddress,
    cameraId,
    recordingResolution,
    model,
    timeZoneName,
    recordedStreamName,
    videoCodec
}) => {

    return {
        logger,
        uidd: `${uid}.${deviceId}`,
        owner: parseInt(uid),
        deviceId: parseInt(deviceId),
        model,
        cloudRecordingEnabled: !!cloudRecordingEnabled,
        romEnabled: !!romEnabled,
        ptzEnabled: !!ptzEnabled,
        audioEnabled: !!audioEnabled,
        recordingResolution,
        wowza,
        name,
        analyticsEnabled: !!analyticsEnabled,
        analyticsScheme,
        // If no permission then this user is owner
        access: permission ?? 'a',
        tags: tags ?? [],
        cloudAdapterId,
        cloudAdapterVersion,
        // Local live
        // Convert IP addresses to hosts
        localLiveHost: localLiveHosts?.map(host => `${host.replace(/[.:]/g, '-')}.${localLiveId}.videoloft.live`),
        localLiveId,
        // install info
        macAddress,
        camId: cameraId,
        timezoneName: timeZoneName,
        recordedStreamName,
        mainstreamLive: !!mainstreamLive,
        videoCodec: videoCodec ?? 'h264'
    };
}

// APIs

// Send command to a camera (e.g. 'livecommand')
/**
 * Send a camera task.
 * @param {string} logger Camera logger.
 * @param {string} uidd Camera uidd.
 * @param {string} command Action.
 * @returns 
 */
export const sendCameraTask = async (logger, uidd, command) => {
    return await privateLoggerRequest(
        logger,
        'GET',
        '/sendcameratask', {
        params: {
            uid: uidd,
            action: command,
            caller: 'web'
        },
    });
};

// Fetch cameras data for all cameras, a specific camera, or a specific account's cameras
// Can only specify one of `uidd` or `owner`
// Returns object mapping uidd to info
// If user does not have access to a specified camera or it has been deleted, it will not be included in response object
const fetchCameras = async ({ makePrivateRequest }, { uidd, owner } = {}) => {
    
    const cameras = (
        await makePrivateRequest(
            'auth',
            'GET',
            '/cameras',
            {
                params: {
                    uidd,
                    owner
                }
            }
        )
    ).data;

    /*
        The old /viewerInfo API used to return a valid logger for every camera, even offline ones, so
        the rest of the web is set up assuming every camera object will include a valid logger domain
        (used for e.g. fetching thumbs, events, etc.). When replacing /viewerInfo with the much faster
        new /cameras API we needed an alternative way to handle this as we didn't want this logic slowing
        down the new API. The frontend now copies logger domains where provided for online (or recently
        offline) cameras into the objects of cameras with no assigned logger. In the rare case none of
        the user's cameras have a valid logger, we have to fetch a domain from GET /logger.
    */

    // Whilst iterating through cameras, when we find a valid logger put it here for others
    let knownLogger;

    // Until we find a logger, store cameras missing a logger and hence need to be later updated
    // here
    const camerasWithoutLogger = [];

    // Format cameras array into an object mapping uidd to info
    const camerasObject = cameras.reduce((acc, cameraUnformatted) => {

        const camera = getCameraObject(cameraUnformatted);

        if (!camera.logger) {
            // We need to find a logger to store against this camera
            if (knownLogger) {
                // We have already found a valid one from a previous camera's data
                camera.logger = knownLogger;
            } else {
                // We haven't yet found a valid logger
                camerasWithoutLogger.push(camera.uidd);
            }
        } else if (!knownLogger) {
            // This camera has a logger so store it for others
            knownLogger = camera.logger;
        }

        return {
            ...acc,
            [camera.uidd]: camera
        };
    }, {});

    if (camerasWithoutLogger.length > 0) {

        // We have some camera objects without a logger
        // Go back and populate them now

        if (!knownLogger) {
            // We did not have any cameras with a valid logger so need to fetch one
            knownLogger = await getValidLogger();
        }

        camerasWithoutLogger.forEach(uidd => {
            camerasObject[uidd].logger = knownLogger;
        });
    }

    return camerasObject;
}

const getCamerasByOwnerRequest = async (requestData, ownerUid) => {

    const cameras = await fetchCameras(requestData, { owner: ownerUid });

    return _.chain(cameras).mapKeys(({ deviceId }) => deviceId).value();
};

/**
 * Fetch all cameras owned by a user.
 * 
 * @param {*} ownerUid 
 * @returns {object<string, object>} Object with device ids as keys and values are objects containing properties of camera.
 */
export const getCamerasByOwner = makePrivateRequestAsUser(getCamerasByOwnerRequest);

export const getCamerasByOwnerUsingRequestData = data => makePrivateRequestWithData(data, getCamerasByOwnerRequest);

/**
 * Fetch info on specific camera.
 * Returns object with uidd as key and value is object containing properties of camera.
 * If user does not have access to the specified camera (or it doesn't exist), then an empty object will be returned.
 * 
 * @param {string} uidd Camera uidd.
 * @returns {object<string, object>} Object mapping uidd to info.
 */
export const getCamera = uidd => makePrivateRequestAsUser(fetchCameras)({ uidd });

/**
 * 
 * @returns {object} All cameras that a user has access to.
 */
export const getAllCameras = makePrivateRequestAsUser(fetchCameras);

/**
 * When we no longer use wowza, switch /viewerInfo requests to use this new faster API instead.
 * When requiring this info for live viewing, do not use this API.
 * @returns {object<string, object>} All cameras that a user has access to, keyed by uidd.
 */
// export const getAllCamerasWithoutLiveViewing = makePrivateRequestAsUser(async ({ makePrivateRequest }) => {

//     const cameras = (
//         await makePrivateRequest(
//             'auth',
//             'GET',
//             '/cameras'
//         )
//     ).data;

//     return cameras.reduce(
//         (
//             acc,
//             {
//                 uid,
//                 id,
//                 model,
//                 cloud_recording_enabled,
//                 rom,
//                 ptz_enabled,
//                 audio_enabled,
//                 recording_resolution,
//                 streamname,
//                 wowza,
//                 phonename,
//                 analytics_enabled,
//                 permission,
//                 tags,
//                 adaptr_cloud_id,
//                 local_live_id,
//                 local_live_host,
//                 mac_address,
//                 phoneid
//             }
//         ) => {
//             const uidd = `${uid}.${id}`;

//             return {
//                 ...acc,
//                 [uidd]: {
//                     uidd,
//                     owner: uid,
//                     deviceId: id,
//                     model,
//                     cloudRecordingEnabled: !!cloud_recording_enabled,
//                     romEnabled: !!rom,
//                     ptzEnabled: !!ptz_enabled,
//                     audioEnabled: !!audio_enabled,
//                     recordingResolution: recording_resolution,
//                     streamName: streamname,
//                     wowza,
//                     name: phonename,
//                     analyticsEnabled: !!analytics_enabled,
//                     // If permission is null, they are owner
//                     access: permission ?? 'a',
//                     tags: tags ?? [],
//                     cloudAdapterId: adaptr_cloud_id,
//                     // Local live
//                     localLiveHost: local_live_host,
//                     localLiveId: local_live_id,
//                     // install info
//                     macAddress: mac_address,
//                     camId: phoneid
//                 }
//             };
//         },
//         {}
//     ); 
// });



// Returns object where keys are uidds and values are objects created using `getStatusObject` above
// uiddsAndLoggers is array of objects each containing keys 'uidd' and 'logger', e.g. [{ uidd: 1, logger: '...' }]
export const getCamerasStatus = async (uiddsAndLoggers) => {

    const uiddsPerLogger = getUiddsPerLogger(uiddsAndLoggers);

    // Don't want to exceed max URL length so set a max query string length comfortably below that
    const MAX_QUERY_STRING_LENGTH = 1800;

    const results = await Promise.all(
        Object.entries(uiddsPerLogger).map(([logger, uidds]) => {

            // Ideally we make one request per logger but if we have too many uidds for a logger we must break it into multiple requests

            // Get length that each uidd will occupy (i.e. `&uidd=${uidd}`.length)
            const paramLengths = uidds.map(uidd => uidd.length + `&uidd=`.length);

            // Divide uidds into groups such that each group's string will be no longer than maximum
            // Iterate over each item in `paramLengths`, and either add to current group, or create new group if needed
            const paramGroups = paramLengths.slice(1).reduce((acc, paramLength) => {
                const currentGroup = acc[acc.length - 1];
                if (currentGroup.length + paramLength > MAX_QUERY_STRING_LENGTH) {
                    // Create new group as this param would take string over length limit
                    return acc.concat({ count: 1, length: paramLength, startIndex: currentGroup.startIndex + currentGroup.count });
                } else {
                    // There's still room in current group so just add to this one
                    currentGroup.count++;
                    currentGroup.length += paramLength;
                    return acc;
                }
            }, [{ count: 1, length: paramLengths[0], startIndex: 0 }]);

            // Make request for each group
            return paramGroups.map(({ startIndex, count }) => privateLoggerRequest(logger, 'GET', '/cameras/status', {
                params: {
                    uidd: uidds.slice(startIndex, startIndex + count),
                },
            }));
        }).flat()
    );

    return results
        .map(({ data: { result, errors } }) => {
            const camerasStatus = {};

            if (Object.keys(result).length > 0) {
                _.forEach(result, ({ devices }) => {
                    _.forEach(devices, (data) => {
                        camerasStatus[data.uidd] = getStatusObject(data);
                    });
                });
            }

            // Handle 'notResident' errors
            // Can treat them as status 'offline'
            if (errors?.length > 0) {
                errors.forEach((obj) => {
                    const uidd = Object.keys(obj)[0];
                    camerasStatus[uidd] = getStatusObject({ status: 'offline', uidd });
                });
            }

            return camerasStatus;
        })
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});
};

// Send command to camera to start/keep live streaming
// Cameras stop live streaming if they haven't received this command in last ~1 min
export const tellCameraToSendLiveVideo = async (logger, uidd, localLive = false) => {
    const command = localLive ? 'locallivecommand' : 'livecommand';
    logCameraTaskSent(uidd, command);
    return await sendCameraTask(logger, uidd, command);
};

// Send command to camera to start/keep sending live thumbs
// Cameras stop live streaming if they haven't received this command in last ~1 min
export const tellCameraToSendLiveThumbs = async (logger, uidd, motionOnly = true) => {
    return await sendCameraTask(logger, uidd, motionOnly ? 'motionthumbscommand' : 'thumbscommand');
};

// Create realtime link with all relevant loggers to receive camera status events
// uiddsAndLoggers is array of objects each containing keys 'uidd' and 'logger', e.g. [{ uidd: 1, logger: '...' }]
// handleMessage is a function which is called whenever an event is received. It gets passed object created from `getStatusObject` (live session) or `getEventObject` (events)
// Returns cleanup function to kill all EventSources
const LIVE = 'liveSession',
    EVENT = 'alert';
export const createLiveSessionRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage
) => createRealtimeLinkWithLoggers(uiddsAndLoggers, handleMessage, LIVE);
export const createEventsRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage
) =>
    createRealtimeLinkWithLoggers(uiddsAndLoggers, handleMessage, EVENT);
const createRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage,
    type
) => {
    // Create realtime link with one logger for specified cameras
    // Backoff is time (in ms) we wait if a link fails before trying to create a new one
    // Initial backoff is 1000ms
    const createRealtimeLinkWithLogger = (logger, uidds, addEventSource, addBackoffTimeout, backoff = 1000) => {
        // Max URL length is 2048 characters so to be safe let's limit query params (excluding 'type') to 1800 characters
        // If we exceed that length, use user uids rather than device uidds
        let uidParam = uidds.map((uidd) => `uidd=${uidd}`).join('&');
        if (uidParam.length > 1800) {
            uidParam = uidds
                .reduce((acc, uidd) => {
                    const uid = uidd.split('.')[0];
                    return acc.includes(uid) ? acc : acc.concat(uid);
                }, [])
                .map((uid) => `uids=${uid}`)
                .join('&');
        }

        const source = new EventSource(
            `https://${logger}/cameras/realtime/live?types=${type}&${uidParam}`,
            { headers: { Authorization: 'ManythingToken ' + getAuthToken() } }
        );

        // Runs when message received
        source.onmessage = (event) => {
            const message = JSON.parse(event.data);

            if (message?.error) {
                console.error(`Realtime link to ${logger} sent error message`);
                console.error(message.error);
            } else if (
                message?.data?.uidd &&
                uidds.includes(message.data.uidd)
            ) {
                if (message.type === LIVE) {
                    // Pass live session event data to handleMessage function
                    handleMessage({
                        ...getStatusObject(message.data),
                        type: LIVE,
                    });
                } else if (message.type === EVENT) {
                    // Pass alert event data to handleMessage function
                    handleMessage({
                        ...getEventObject(message.data),
                        type: 'event',
                        logger,
                    });
                }
            }
        };

        // Runs when error occurs
        source.onerror = (error) => {
            // Error occured either during creation of link or whilst it was open
            // Link can no longer be used
            // Close it and open new one
            console.error(`Realtime link to ${logger} failed`);
            logError(error, { identifier: 'SSE', logger });

            // Must close link before creating a new one
            source.close();

            // After time specified in backoff, try to create a new link
            addBackoffTimeout(
                setTimeout(() => {
                    // Double backoff on each failure, up to maximum of 60000ms
                    createRealtimeLinkWithLogger(
                        logger,
                        uidds,
                        addEventSource,
                        addBackoffTimeout,
                        Math.min(backoff * 2, 60000)
                    );
                }, backoff)
            );
        };

        // Must pass all opened event sources to addEventSource so caller can close them when necessary
        addEventSource(source);
    };

    // Group cameras by logger
    // We create one link per logger and it sends us events about the cameras we ask for
    const uiddsPerLogger = getUiddsPerLogger(uiddsAndLoggers);

    // Event sources
    const sources = [];

    // Timeouts created to set up new links on error
    // When `killAllLinks` is called, these must also be cleared to make sure no new links get created
    const timeouts = [];

    // Create a link for each logger
    for (const logger in uiddsPerLogger) {
        createRealtimeLinkWithLogger(logger, uiddsPerLogger[logger], source => sources.push(source), timeout => timeouts.push(timeout));
    }

    // Return cleanup function to kill all EventSources
    const killAllLinks = () => {
        timeouts.forEach((timeout) => clearTimeout(timeout));
        sources.forEach((source) => source.close());
    };

    return killAllLinks;
};

/**
 * Updates tags for multiple cameras at once. The tags for each camera will be completely overwritten by the array sent in this request.
 * @param {Object} newTags - object mapping camera uidds to new tag array, e.g. { '123.1': ['office', 'parking'], '456.3': [ 'office', 'garden' ]}
 * @return {import('axios').AxiosPromise}
 */
export const updateTags = (newTags) => {
    return privateAuthenticatorRequest('PUT', `/cameras/tags`, {
        data: {
            // Array of uidds being edited
            uidd: Object.keys(newTags),

            // changes must be formatted like [{ uidd: '123.1', tags: ['office', 'parking']}, { uidd: '456.3', tags: ['office', 'garden']}]
            changes: Object.entries(newTags).map(([uidd, tags]) => ({ uidd, tags }))
        }
    });
}

// Fetches all distinct tags that have been applied to either a specific set of cameras or any camera the user has access to
// `uidds` is either array of string uidds, or leave undefined to mean all cameras
export const getAllTags = async (uidds) => {

    const { data } = await privateAuthenticatorRequest('GET', `/cameras/tags`, {
        params: {
            uidd: uidds
        }
    });

    return data;
}

// Command must be one of 'up', 'down', 'left', 'right, 'in' or 'out'
export const sendCameraPTZCommand = (logger, uidd, command) =>
    sendCameraTask(logger, uidd, `ptz_${command}`);

// Returns base64
export const getLastThumb = async (logger, uidd) => {
    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        `/getalertthumb/${uidd}`,
        {
            responseType: 'arraybuffer',
            params: {
                // Cache bust to make sure we get most recent thumb
                t: new Date().getTime()
            }
        }
    );

    return Buffer.from(data, 'binary').toString('base64');
};

/**
 * Get first still uploaded after a specified time.
 * @param {string} logger Logger to make request to.
 * @param {string} uidd Camera uidd.
 * @param {number} fromTime Timestamp in ms.
 * @returns
 */
export const getFirstStillAfterTime = async (logger, uidd, fromTime) => {
    const [ownerUid, deviceId] = uidd.split('.');

    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        `/still/${ownerUid}/${deviceId}`,
        {
            params: {
                after: fromTime
            },
            responseType: 'blob'
        }
    );

    return data;
}

/**
 * Get SD still for a camera.
 * @param {string} logger Logger to make request to.
 * @param {string} uidd Camera uidd.
 * @param {number} time Still timestamp in ms.
 * @returns 
 */
export const getSDStill = async (logger, uidd, time) => {
    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        // Token can be passed in header but path needs something in its place
        `/getstill/${uidd}/${time / 1000}/token`,
        {
            responseType: 'blob'
        }
    );

    return data;
}


/* -------------------------------- Settings -------------------------------- */

// This is actually only a soft delete (sets is_camera to false in device table)
export const deleteCamera = (uidd) => {
    return privateAuthenticatorRequest('GET', '/deldevice', {
        params: {
            uid: uidd,
        },
    });
};

export const deregisterCamerasFromCloudId = (cloudId, deviceIds) => {
    return privateAuthenticatorRequest(
        'DELETE',
        `/users/:uid/adaptr/${cloudId}`,
        {
            data: {
                deviceIds: deviceIds
            }
        }
    );
};

export const uninstallCamera = (logger, uidd, all = false) => {
    let command = 'uninstall';
    if (all) {
        command += '_all';
    }
    return sendCameraTask(logger, uidd, command);
};

export const tellAdapterThatCameraSettingsHaveChanged = (logger, uidd) => {
    return sendCameraTask(logger, uidd, 'getconfig');
};

export const changeCameraName = (uidd, newName) => {
    return privateAuthenticatorRequest('PUT', '/phonename', {
        params: {
            uid: uidd,
            name: newName,
            token: getAuthToken(),
        },
    });
};

/**
 * Enabled/disable cloud recording for a camera. 
 * @param {string} uidd Camera uidd.
 * @param {boolean} cloudRecordingEnabled Whether to enable or disable cloud recording.
 * @returns 
 */
export const changeCameraCloudRecordingEnabled = async (uidd, cloudRecordingEnabled) => {
    const [ownerId, deviceId] = uidd.split('.');

    const { data } = await privateAuthenticatorRequest(
        'PUT',
        `/simple/${ownerId}/${deviceId}/config/cloudrecordingenabled`,
        {
            data: {
                token: getAuthToken(),
                cloudRecordingEnabled
            }
        }
    );

    // This is one of those stupid APIs that returns a 200 if it doesn't work
    if (data.err) {
        throw new Error(data.err);
    }

    return data;
}
/**
 * Enabled/disable cloud recording for multiple cameras. 
 * @param {string[]} uidd Array of camera uidds.
 * @param {boolean} cloudRecordingEnabled Whether to enable or disable cloud recording.
 * @returns 
 */
const changeCloudRecordingForCamerasRequest = async ({ makePrivateRequest }, uidd, cloudRecordingEnabled) => {
    try {
        const { data } = await makePrivateRequest(
            'auth',
            'PUT',
            `/cameras/config/cloudrecordingenabled`,
            {
                data: {
                    uidd,
                    cloudRecordingEnabled
                }
            }
        );
        return data;
    } catch (err) {
        console.error('/cloudrecordingenabled', err);
        throw err;
    }
};
export const changeCamerasCloudRecordingEnabled = (uidd, cloudRecordingEnabled, isManager) => makePrivateRequestAsRole(isManager)(changeCloudRecordingForCamerasRequest)(uidd, cloudRecordingEnabled);

/**
 * Enabled/disable analytics for a camera.
 * 
 * @param {string} uidd Camera uidd.
 * @param {boolean} analyticsEnabled Whether to enable or disable analytics. 
 * @returns 
 */
export const changeCameraAnalyticsEnabled = (uidd, analyticsEnabled) => {

    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/camera/${ownerId}/${deviceId}/config/analytics`,
        {
            data: {
                enabled: analyticsEnabled
            }
        }
    );
};

/**
 * Change camera recording mode to rom/continuous.
 * @param {string} uidd Camera Uidd.
 * @param {('rom', 'continuous')} recordingMode Rom or continuous.
 * @returns
 */
export const changeCameraRecordingMode = (uidd, recordingMode) => {
    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/camera/${ownerId}/${deviceId}/config/recordingmode`,
        {
            data: {
                mode: recordingMode
            }
        }
    );
}

/**
 * Change multiple cameras recording modes to rom/continuous.
 * @param {string[]} uidd Array of camera Uidds.
 * @param {('rom', 'continuous')} recordingMode Rom or continuous.
 * @returns
 */
const changeRecordingModesForCamerasRequest = async ({ makePrivateRequest }, uidd, recordingMode) => {
    try {
        const { data } = await makePrivateRequest(
            'auth',
            'PUT',
            `/cameras/config/recordingmode`,
            {
                data: {
                    uidd,
                    mode: recordingMode
                }
            }
        );
        return data;
    } catch (err) {
        console.error('/recordingmode', err);
        throw err;
    }
};
export const changeCamerasRecordingModes = (uidd, recordingMode, isManager) => makePrivateRequestAsRole(isManager)(changeRecordingModesForCamerasRequest)(uidd, recordingMode);

export const RESOLUTION_TO_NVRMODE_MAP = {
    'SD': 1,
    '2MP': 0,
    '4MP': 3,
    '8MP': 2
};

/**
 * Change camera recorded video quality.
 * @param {string} uidd Camera uidd.
 * @param {('SD', '2MP', '4MP', '8MP')} resolution Recorded video quality.
 * @returns 
 */
export const changeCameraRecordingResolution = (uidd, resolution) => {
    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/simple/${ownerId}/${deviceId}/config/nvrmode`,
        {
            headers: {
                'api-version': '2.0.0'
            },
            data: {
                nvrmode: RESOLUTION_TO_NVRMODE_MAP[resolution]
            }
        }
    );
};

/**
 * Change camera recorded video quality.
 * @param {string[]} uidd Array of camera uidds.
 * @param {('SD', '2MP', '4MP', '8MP')} resolution Recorded video quality.
 * @returns 
 */
const changeRecordingResolutionForCamerasRequest = async ({ makePrivateRequest }, uidd, resolution) => {
    try {
        const { data } = await makePrivateRequest(
            'auth',
            'PUT',
            `/cameras/config/nvrmode`,
            {
                data: {
                    uidd,
                    nvrmode: RESOLUTION_TO_NVRMODE_MAP[resolution]
                }
            }
        );
        return data;
    } catch (err) {
        console.error('/nvrmode', err);
        throw err;
    }
};
export const changeCamerasRecordingResolutions = (uidd, resolution, isManager) => makePrivateRequestAsRole(isManager)(changeRecordingResolutionForCamerasRequest)(uidd, resolution);

const getAnalyticsRulesRequest = async ({ makePrivateRequest }, uidds) => {
    const splitUidds = splitIntoChunks(uidds);

    // Make API requests for each subarray
    const requests = splitUidds.map(async (uidd) => {
        const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/analytics/alerts/rules`,
        {
            params: {
                uidd: uidd,
            }
        }
        );
        return data;
    });
    return Promise.all(requests);
};
export const getAnalyticsRulesForCameras = (uidds, isManager = false) => makePrivateRequestAsRole(isManager)(getAnalyticsRulesRequest)(uidds);

/**
 * Get list of analytics polygons set up for one or more cameras.
 * @param {string} uidds Cameras uidds.
 * @returns {AnalyticsArea[]} Array of areas.
 */
 const getCamerasAnalyticsAreasRequest = async ({ makePrivateRequest }, uidds) => {
    const splitUidds = splitIntoChunks(uidds);

    // Make API requests for each subarray
    const requests = splitUidds.map(async (uidd) => {
        const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/analytics/areas`,
        {
            params: {
                uidd: uidd,
            }
        }
        );
        return data;
    });
    return Promise.all(requests);
};
export const getCamerasAnalyticsAreas = (uidds, isManager = false) => makePrivateRequestAsRole(isManager)(getCamerasAnalyticsAreasRequest)(uidds);

const getCamerasSettingsRequest = async ({ makePrivateRequest}, uidds, uid) => {
    const splitUidds = splitIntoChunks(uidds);

    // Make API requests for each subarray
    const requests = splitUidds.map(async (uidd) => {
        const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/cameras/${uid}/config`,
        {
            params: {
                uidd: uidd,
            }
        }
        );
        return data;
    });
    return Promise.all(requests);
};
export const getConfigForCameras = (uidds, uid, isManager = false) => makePrivateRequestAsRole(isManager)(getCamerasSettingsRequest)(uidds, uid);

/**
 * Change cameras non-license/non-schedule related settings.
 * @param {string[]} uidd Cameras uidds.
 * @param {} settings object of settings to patch
 * @returns 
 */
const patchCameraSettingsRequest = async ({ makePrivateRequest }, uidd, settings, uid) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'PATCH',
            `/cameras/${uid}/config`,
            {
                data: {
                    uidd,
                    settings
                }
            }
        );
        return res;
    } catch (err) {
        console.error('/cameras/config', err);
        throw err;
    }
};
export const patchConfigForCameras = (uidds, settings, uid, isManager = false) => makePrivateRequestAsRole(isManager)(patchCameraSettingsRequest)(uidds, settings, uid);

const changeCamerasAnalyticsSchemeRequest = async ({ makePrivateRequest }, uidd, scheme, enabled) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'PUT',
            `/cameras/config/analytics`,
            {
                data: {
                    uidd,
                    enabled,
                    scheme
                }
            }
        );
        return res;
    } catch (err) {
        console.error('/cameras/config/analytics/addon', err);
        throw err;
    }
};
export const changeCamerasAnalyticsScheme = (uidds, scheme, enabled, isManager = false) => makePrivateRequestAsRole(isManager)(changeCamerasAnalyticsSchemeRequest)(uidds, scheme, enabled);

const addScheduleRequest = async ({ makePrivateRequest }, uidd, event, uid) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'POST',
            `/schedule/${uid}/${event.category}/${event.local_time}/${event.action}`,
            {
                data: {
                    uidd,
                    daysOfWeek: event.day_of_week
                }
            }
        );
        return res;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const addScheduleEvent = (uidd, event, uid, isManager) => makePrivateRequestAsRole(isManager)(addScheduleRequest)(uidd, event, uid);

const deleteScheduleRequest = async ({ makePrivateRequest }, uidd, event, uid) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'DELETE',
            `/schedule/${uid}/${event.category}/${event.local_time}`,
            {
                data: {
                    uidd,
                    daysOfWeek: [event.day_of_week]
                }
            }
        );
        return res;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const deleteScheduleEvent = (uidd, event, uid, isManager) => makePrivateRequestAsRole(isManager)(deleteScheduleRequest)(uidd, event, uid);

const editScheduleRequest = async ({ makePrivateRequest }, uidd, event, uid) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'PUT',
            `/schedule/${uid}/${event.category}/${event.local_time}/${event.action}`,
            {
                data: {
                    uidd,
                    daysOfWeek: event.day_of_week,
                    localTimeToReplace: event.localTimeToReplace
                }
            }
        );
        return res;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const editScheduleEvent = (uidd, event, uid, isManager) => makePrivateRequestAsRole(isManager)(editScheduleRequest)(uidd, event, uid);

const addAlertRuleRequest = async ({ makePrivateRequest }, uidd, rule) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'POST',
            `/analytics/alerts/rules`,
            {
                data: {
                    uidd,
                    rule
                }
            }
        );
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const addAlertRule = (uidd, rule, isManager) => makePrivateRequestAsRole(isManager)(addAlertRuleRequest)(uidd, rule);

const deleteAlertRequest = async ({ makePrivateRequest }, uidd, valuesToDelete) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'DELETE',
            `/analytics/alerts/rules`,
            {
                params: {
                    uidd
                },
                data: {
                    rules: valuesToDelete
                }
            }
        );
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const deleteAlertRule = (uidd, rulesToDelete, isManager) => makePrivateRequestAsRole(isManager)(deleteAlertRequest)(uidd, rulesToDelete);

const editAlertRequest = async ({ makePrivateRequest }, uidd, ruleIdsToUpdate, newRule) => {
    try {
        const res = await makePrivateRequest(
            'auth',
            'PATCH',
            `/analytics/alerts/rules`,
            {
                data: {
                    uidd,
                    ruleIdsToUpdate: ruleIdsToUpdate,
                    rule: newRule
                }
            }
        );
        return res.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
export const editAlertRule = (uidd, idsToUpdate, newRule, isManager) => makePrivateRequestAsRole(isManager)(editAlertRequest)(uidd, idsToUpdate, newRule);


export const pollCamerasStatus = (uiddsAndLoggers, statusToCheck, interval = 3000, maxPolls = 6) => {
    return new Promise((resolve, reject) => {
        let pollCount = 0;
        let statusReached = [];
        const checkStatus = async () => {
            try {
                pollCount++;
                const response = await getCamerasStatus(uiddsAndLoggers);
                const cameraStatuses = _.entries(response).reduce((acc, [uidd, c]) => ({...acc, [uidd]: c.status}), {});
                statusReached = _.uniq([...statusReached, ...(_.keys(cameraStatuses).filter(uidd => statusToCheck.includes(cameraStatuses[uidd])))]);
                const uniqCameraStatuses = _.uniq(_.values(cameraStatuses));
                if (uniqCameraStatuses.every(status => statusToCheck.includes(status))) {
                    resolve({ pollCount, cameraStatuses, statusReached });
                } else if (pollCount >= maxPolls) {
                    reject({ error: new Error('Max polls reached'), pollCount, cameraStatuses, statusReached });
                } else {
                    setTimeout(checkStatus, interval);
                }
            } catch (error) {
                reject({ pollCount, error, statusReached });
            }
        };
        checkStatus();
    });
};

/**
 * Attempts to uninstall multiple cameras and verifies their status change.
 *
 * This function implements a retry mechanism for uninstalling cameras and checking their status.
 * It performs the following steps:
 * 1. Attempts to uninstall all specified cameras.
 * 2. Polls the status of these cameras to verify they have reached the "uninstalled" state.
 * 3. If unsuccessful, it retries the entire process up to a specified number of times.
 *
 * @param {Array<Object>} uiddsAndLoggers - An array of objects, each containing a camera's UIDD and logger.
 * @param {number} [maxCycles=6] - Maximum number of retry cycles for the entire uninstall process.
 * @param {number} [maxPolls=6] - Maximum number of status checks per cycle.
 * @param {number} [pollInterval=5000] - Time interval (in milliseconds) between status checks.
 *
 * @returns {Promise<Object>} A promise that resolves with the result of successful uninstallation,
 *                            including poll count and final statuses.
 */
export const uninstallCamerasWithCheck = async (uiddsAndLoggers, maxCycles = 6, maxPolls = 6, pollInterval = 5000) => {
    const desiredStatuses = ["uninstalled", "offline"];
    let statusReached = [];
    for (let cycle = 1; cycle <= maxCycles; cycle++) {
        try {
            await Promise.all(
                uiddsAndLoggers.map(({ uidd, logger }) => 
                    uninstallCamera(logger, uidd)
                        .then(() => {
                            logCameraTaskSent(uidd, 'uninstall');
                            return true;
                        })
                        .catch(error => {
                            logError(error, { identifier: 'Dashboard - uninstall camera', uidd });
                        })
                )
            );
        } catch (error) {
            logUninstallTask(_.values(uiddsAndLoggers).map(({ uidd }) => uidd), { error, info: 'Failure sending camera task'});
            continue; // Move to the next cycle to retry if there's an error
        }

        try {
            const result = await pollCamerasStatus(uiddsAndLoggers, desiredStatuses, pollInterval, maxPolls);
            logUninstallTask(_.values(uiddsAndLoggers).map(({ uidd }) => uidd), { polls: result.pollCount, cycle: cycle, info: 'Statuses changed to uninstalled' });
            statusReached = _.uniq([...statusReached, ...result.statusReached]);
            return statusReached;
        } catch (error) {
            statusReached = _.uniq([...statusReached, ...error?.statusReached]);
        }
    }

    logUninstallTask(_.values(uiddsAndLoggers).map(({ uidd }) => uidd), { cycles: maxCycles, info: 'All Camera statuses not changed to uninstalled', statusReached });
    return statusReached;
};
